import { Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { OverviewComponent } from './overview/overview.component';
import { shellRoutes as TalentScanRoutes } from '../talentscans/talentscans-routing.module';
import { shellRoutes as JobScanRoutes } from '../jobscans/jobscans-routing.module';
var routes = [
    { path: '', component: ShellComponent,
        children: [
            { path: '', component: OverviewComponent, pathMatch: 'full' }
        ].concat(TalentScanRoutes, JobScanRoutes)
    }
];
var ShellRoutingModule = /** @class */ (function () {
    function ShellRoutingModule() {
    }
    return ShellRoutingModule;
}());
export { ShellRoutingModule };
