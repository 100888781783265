/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../node_modules/kazi-reports-prod/kazi-reports-prod.ngfactory";
import * as i6 from "kazi-reports-prod";
import * as i7 from "./report.component";
import * as i8 from "../../../core/services/user-settings.service";
import * as i9 from "../../api/identifying-api.generated";
import * as i10 from "../../api/administrator-api.generated";
var styles_ReportComponent = [i0.styles];
var RenderType_ReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportComponent, data: {} });
export { RenderType_ReportComponent as RenderType_ReportComponent };
function View_ReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "/talents"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("header.talents")); _ck(_v, 3, 0, currVal_3); }); }
function View_ReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "/jobs"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("header.jobscans")); _ck(_v, 3, 0, currVal_3); }); }
function View_ReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("report.report")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [["aria-current", "page"], ["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.isCompareTalentWithJob ? "/talents/" : "/jobs/") + _co.parameterService.parameters.id) + "/report"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("report.report")); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("report.compare")); _ck(_v, 7, 0, currVal_4); }); }
function View_ReportComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kazi-talent-spider-report", [], null, [[null, "error"], [null, "compare"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } if (("compare" === en)) {
        var pd_1 = (_co.compareTalentWithJob($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TalentSpiderReportComponent_0, i5.RenderType_TalentSpiderReportComponent)), i1.ɵdid(2, 114688, null, 0, i6.TalentSpiderReportComponent, [i6.ɵb, i6.ɵd, i6.ParameterService], null, { error: "error", compare: "compare" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kazi-jobscan-spider-report", [], null, [[null, "error"], [null, "compare"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } if (("compare" === en)) {
        var pd_1 = (_co.compareJobWithTalent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_JobscanSpiderReportComponent_0, i5.RenderType_JobscanSpiderReportComponent)), i1.ɵdid(2, 114688, null, 0, i6.JobscanSpiderReportComponent, [i6.ɵb, i6.ɵd, i6.ParameterService], null, { error: "error", compare: "compare" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kazi-compare-report-wrapper", [], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ɵf_0, i5.RenderType_ɵf)), i1.ɵdid(2, 114688, null, 0, i6.ɵf, [i6.ɵb, i6.ɵd, i6.ParameterService], null, { error: "error" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("report.no_expectations_found_for_talent")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("report.no_expectations_found_for_jobscan")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "report-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_11)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_12)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.talentScanReport; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.jobScanReport; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ReportComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "report"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_7)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_9)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_10)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.talentScanReport; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.jobScanReport; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isCompareReport; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.error; _ck(_v, 8, 0, currVal_3); }, null); }
function View_ReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "nav", [["aria-label", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_6)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.talentScanReport || _co.isCompareTalentWithJob); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.jobScanReport || _co.isCompareJobWithTalent); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isCompareReport; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isCompareReport; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.loaded; _ck(_v, 12, 0, currVal_4); }, null); }
function View_ReportComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kazi-talent-spider-report", [], null, [[null, "error"], [null, "loaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } if (("loaded" === en)) {
        var pd_1 = (_co.showPrintDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TalentSpiderReportComponent_0, i5.RenderType_TalentSpiderReportComponent)), i1.ɵdid(2, 114688, null, 0, i6.TalentSpiderReportComponent, [i6.ɵb, i6.ɵd, i6.ParameterService], null, { loaded: "loaded", error: "error" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kazi-jobscan-spider-report", [], null, [[null, "error"], [null, "loaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } if (("loaded" === en)) {
        var pd_1 = (_co.showPrintDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_JobscanSpiderReportComponent_0, i5.RenderType_JobscanSpiderReportComponent)), i1.ɵdid(2, 114688, null, 0, i6.JobscanSpiderReportComponent, [i6.ɵb, i6.ɵd, i6.ParameterService], null, { loaded: "loaded", error: "error" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_14)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_15)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.talentScanReport; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.jobScanReport; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_13)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.print; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.loaded && _co.print); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-report", [], null, null, null, View_ReportComponent_0, RenderType_ReportComponent)), i1.ɵdid(1, 245760, null, 0, i7.ReportComponent, [i6.ParameterService, i2.ActivatedRoute, i4.TranslateService, i8.UserSettingsService, i2.Router, i9.IdentifyingClient, i10.AdministratorClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportComponentNgFactory = i1.ɵccf("kazi-report", i7.ReportComponent, View_ReportComponent_Host_0, {}, {}, []);
export { ReportComponentNgFactory as ReportComponentNgFactory };
