/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../shared/components/spinner/spinner.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./overview.component";
import * as i8 from "@azure/msal-angular/dist/msal.service";
import * as i9 from "../../core/services/accounts.service";
var styles_OverviewComponent = [i0.styles];
var RenderType_OverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewComponent, data: {} });
export { RenderType_OverviewComponent as RenderType_OverviewComponent };
function View_OverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_OverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 13, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [" & "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = _ck(_v, 9, 0, "/talents"); _ck(_v, 8, 0, currVal_4); var currVal_8 = _ck(_v, 15, 0, "/jobs"); _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("overview.welcome")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("overview.welcome_intro")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("overview.talents")); _ck(_v, 10, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 14).target; var currVal_7 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("overview.jobscans")); _ck(_v, 16, 0, currVal_9); }); }
function View_OverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("overview.welcome_unregistered")); _ck(_v, 1, 0, currVal_0); }); }
function View_OverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-sign-in"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("overview.please_login")); _ck(_v, 2, 0, currVal_0); }); }
export function View_OverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "overview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_4)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.isLoggedIn && _co.loaded) && _co.hasAccounts); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.isLoggedIn && _co.loaded) && !_co.hasAccounts); _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.isLoggedIn; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_OverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-overview", [], null, null, null, View_OverviewComponent_0, RenderType_OverviewComponent)), i1.ɵdid(1, 245760, null, 0, i7.OverviewComponent, [i8.MsalService, i9.AccountsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewComponentNgFactory = i1.ɵccf("kazi-overview", i7.OverviewComponent, View_OverviewComponent_Host_0, {}, {}, []);
export { OverviewComponentNgFactory as OverviewComponentNgFactory };
