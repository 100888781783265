import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterService, ReportType } from 'kazi-reports-prod';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { skipLast, filter, switchMap, finalize } from 'rxjs/operators';
import { IdentifyingClient } from '../../api/identifying-api.generated';
import { AdministratorClient } from '../../api/administrator-api.generated';
var ReportComponent = /** @class */ (function () {
    function ReportComponent(parameterService, route, translate, userSettingsService, router, identifyingClient, administratorClient) {
        this.parameterService = parameterService;
        this.route = route;
        this.translate = translate;
        this.userSettingsService = userSettingsService;
        this.router = router;
        this.identifyingClient = identifyingClient;
        this.administratorClient = administratorClient;
        this.subscriptions = [];
        this.parameterService.parameters = {
            identifyingApiUrl: environment.api.identifyingUrl,
            taggingApiUrl: environment.api.taggingUrl,
            language: this.translate.currentLang,
            theme: 'kazi',
            themeCDN: 'https://cdn.kazi.be',
        };
    }
    Object.defineProperty(ReportComponent.prototype, "talentScanReport", {
        get: function () {
            return this.reportType === ReportType.TalentScan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "jobScanReport", {
        get: function () {
            return this.reportType === ReportType.JobScan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareReport", {
        get: function () {
            return this.isCompareTalentWithJob || this.isCompareJobWithTalent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareTalentWithJob", {
        get: function () {
            return this.reportType == ReportType.CompareTalentWithJob;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareJobWithTalent", {
        get: function () {
            return this.reportType == ReportType.CompareJobWithTalent;
        },
        enumerable: true,
        configurable: true
    });
    ReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.use(this.route.snapshot.params.lang);
        this.loaded = false;
        this.error = false;
        this.print = this.route.snapshot.url.filter(function (segment) { return segment.path === 'print'; }).length > 0;
        if (this.print) {
            document.body.classList.add('body-overflow');
        }
        var id = this.route.snapshot.params.id;
        this.reportType =
            this.route.snapshot.url.filter(function (segment) { return segment.path === 'talents'; }).length > 0 ? ReportType.TalentScan : ReportType.JobScan;
        var isCompare = this.route.snapshot.url.some(function (segment) { return segment.path === 'compare'; });
        if (isCompare) {
            this.reportType = this.reportType == ReportType.TalentScan ? ReportType.CompareTalentWithJob : ReportType.CompareJobWithTalent;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }
        this.parameterService.parameters.id = id;
        this.parameterService.parameters.reportType = this.reportType;
        this.setTheme(id);
        var currentAccountSub = this.userSettingsService.currentAccount$
            .pipe(skipLast(1), filter(function (p) { return !!p; }))
            .subscribe(function (account) {
            _this.router.navigateByUrl(_this.talentScanReport ? 'talents' : 'jobs');
        });
        this.subscriptions.push(currentAccountSub);
    };
    ReportComponent.prototype.setTheme = function (id) {
        var _this = this;
        var dtoObervable = this.reportType === ReportType.TalentScan || this.reportType == ReportType.CompareTalentWithJob
            ? this.identifyingClient.getTalentScan(id)
            : this.identifyingClient.getJobScan(id);
        var themeSub = dtoObervable
            .pipe(switchMap(function (item) { return _this.administratorClient.getClientById(item.clientId); }), finalize(function () { return (_this.loaded = true); }) // loaded=true shows the report
        )
            .subscribe(function (client) {
            _this.parameterService.parameters.theme = client.theme.toLowerCase();
        });
        this.subscriptions.push(themeSub);
    };
    ReportComponent.prototype.onError = function ($event) {
        this.error = true;
        var reportTypeName = this.reportType === 1 ? 'talentscan' : 'jobscan';
        throw new Error("Error loading " + reportTypeName + " spider report (" + location.href);
    };
    ReportComponent.prototype.showPrintDialog = function () {
        setTimeout(function () { return print(); }, 500);
    };
    ReportComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    ReportComponent.prototype.compareTalentWithJob = function (_a) {
        var jobScanId = _a.jobScanId, talentScanId = _a.talentScanId;
        console.log(jobScanId, talentScanId);
        this.router.navigate(['talents', talentScanId, 'compare', jobScanId]);
    };
    ReportComponent.prototype.compareJobWithTalent = function (_a) {
        var jobScanId = _a.jobScanId, talentScanId = _a.talentScanId;
        console.log(jobScanId, talentScanId);
        this.router.navigate(['jobs', jobScanId, 'compare', talentScanId]);
    };
    return ReportComponent;
}());
export { ReportComponent };
