<kazi-spinner *ngIf="!loaded"></kazi-spinner>
<div class="overview">
  <div *ngIf="isLoggedIn && loaded && hasAccounts">
    <p>{{ 'overview.welcome' | translate }}</p>
    <p>{{ 'overview.welcome_intro' | translate }} <a [routerLink]="['/talents']">{{ 'overview.talents' | translate }}</a>
    & <a [routerLink]="['/jobs']">{{ 'overview.jobscans' | translate }}</a></p>
  </div>
  <div *ngIf="isLoggedIn && loaded && !hasAccounts">
    {{ 'overview.welcome_unregistered' | translate }}
  </div>
  <div *ngIf="!isLoggedIn">
    <a (click)="login($event)" href>{{ 'overview.please_login' | translate }}<i class="fa fa-sign-in"></i></a>
  </div>
</div>
